export const resultLimit = 20;
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
export const mobileRegex = /^([6789]\d{9})$/;
export const placeofbirthRegex = /^[a-zA-Z0-9]+$/;
export const nomineeNameRegex = /^[A-Za-z\s]+$/;
export const entityNameRegex = /^[A-Za-z\s]+$/;
export const otherRelationRegex = /^[A-Za-z\s]+$/;
export const giinNumberRegex = /^[A-Za-z0-9]{19}$/;
export const annexureARegex = /^[A-N]$/;
export const annexureBRegex = /^(0+[1-7]|NA|N)$/;
export const nriaddressRegex = /^[,-.\w\s]+$/;
export const cityPlaceRegex = /^[a-zA-Z\s]+$/;
export const pincodeRegex = /\[D]$/;
export const stateNameRegex = /^[a-zA-Z.\s]+$/;
export const natureOfBusinessRegex = /^[A-Za-z]+$/;
export const redeemAmountRegex = /^(\d+\.?\d*)?$/;
export const UNAUTHORIZED_USER_ERR_MSG =
  'Session Expired, Please Sign in to continue.';
