import * as Sentry from '@sentry/react';
import axios from 'axios';

import Toast from '../common/toast/Toast';
import { getInfoWithKey } from '../page/store/LocalStorageInfoManager';
import {
  TOKEN_KEY,
  abortController,
  getLoader,
  redirectUserForAuthentication,
  removeLoader,
  removeToken,
} from '../utility/utility';


const fetchClient = (version) => {
  const headers = { 'api-key': atob(process.env.REACT_APP_API_KEY) };
  const instance = axios.create({
    baseURL: process.env.REACT_APP_TARRAKKI_LEGACY_API_URL + version,
    headers: headers,
    signal: abortController.signal,
  });
  // Add a request interceptor
  instance.interceptors.request.use((config) => {
    document.body.appendChild(getLoader());
    const uD = getInfoWithKey('uD');
    const newToken = localStorage.getItem(TOKEN_KEY);
    if (newToken) {
      config.headers.Authorization = `Bearer ${newToken}`;
    }
    const token = uD && uD.data ? uD.data?.token : null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    (res) => {
      removeLoader();
      if (res && res.data && res.data.status) {
        if (res.data.status.message && res.data.status.code === 0) {
          if (!res.config.url.includes('/tcs/tax-status')) {
            Toast.error(res.data.status.message);
          }
        }
      }
      return res;
    },
    (error) => {
      removeLoader();
      if (error?.response && error.response?.status !== 401) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          scope.setFingerprint([`http_status:${error?.response?.status}`]);
          Sentry.captureException(error);
        });
      }
      if (
        !abortController.signal.aborted &&
        error.response &&
        error.response.status === 401
      ) {
        Toast.error('Your session has expired, please log in to continue');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        abortController.abort();
        redirectUserForAuthentication();
      } else if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 499 &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === 'string') {
          Toast.error(error.response.data.message);
        } else if (
          error.response?.data?.message[0] &&
          Object.values(error.response.data.message[0])[0][0]
        ) {
          const errorObj = Object.values(error.response.data.message[0])[0][0];
          Toast.error(errorObj);
        } else {
          Toast.error(
            `An error occurred with your request. Please check your input and try again or reach out to our customer support team`
          );
        }
      } else if (
        error.response &&
        error.response.status >= 500 &&
        error.response.status <= 599
      ) {
        Toast.error(
          `Something went wrong. Please contact our customer support team.`
        );
      } else if (error.message) {
        Toast.error(error.message);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default fetchClient('v7');
