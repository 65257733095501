import { getAppConfig } from '../api/apps_url';
import { IssuerOut } from '../api/fdApi/services/issuer.service';
import Toast from '../common/toast/Toast';

export const TENANT_NAME_MAPPING: {
  [key: string]: string;
} = {
  'lgtindia-online': 'lgtindia',
};

type DateValues = Date | null | undefined;

const controller = new AbortController();

export const abortController = controller;

export const get_issuer_tenure_string = (issuer: IssuerOut): string => {
  return `${issuer.min_tenure} ${issuer.min_tenure_unit} - ${issuer.max_tenure} ${issuer.max_tenure_unit}`;
};

export const getTruncatedStringFromEndToDisplay = (message: string) => {
  const MAX_STRING_LENGTH = 30;
  if (message.length <= MAX_STRING_LENGTH) {
    return message;
  }
  return '...' + message.slice(-MAX_STRING_LENGTH);
};

export const fileToBase64 = (file: File): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(null);
  });

export const isEmptyObject = (object: Object) => {
  return (
    object && Object.keys(object).length === 0 && object.constructor === Object
  );
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const hasAssistModePermission = (list_of_permissions: string[]) => {
  // Specified by the backend
  const request_permissions = [
    'tenant::users:assist:select_groups',
    'tenant::users:assist:all',
  ];

  if (!list_of_permissions) {
    return false;
  }

  if (list_of_permissions.includes('*')) return true;
  return request_permissions.some((permission) => {
    return list_of_permissions.includes(permission);
  });
};

export const hasAdminAccess = (list_of_permissions: string[]) => {
  return list_of_permissions.includes('*');
};

// For Transactions screen's Query params filter date
export const formatQueryParamsDateString = (
  inputDate: DateValues,
  format: string
): string => {
  if (inputDate) {
    const date = String(inputDate.getDate()).padStart(2, '0'); // dd -- 2-digit
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // mm -- 2-digit
    const year = String(inputDate.getFullYear()); // yyyy -- 4-digit
    if (format === 'dd/mm/yyyy') return `${date}/${month}/${year}`;
    if (format === 'yyyy-mm-dd') return `${year}-${month}-${date}`;
  }
  return '';
};

export const getTenantName = () => {
  const inputString = window.location.hostname;
  const inputStringArray = inputString?.split('.');
  const regex =
    /\b-?(mf|uat|mutual|invest|funds|corporates|fd|fixed|deposits)\d?|sandbox|fd/g;
  const result = inputStringArray[0]?.replace(regex, '');
  if (result === 'localhost') return 'localhost';
  else if (Object.keys(TENANT_NAME_MAPPING).includes(result))
    return TENANT_NAME_MAPPING[result];
  return result?.length > 0 ? result : inputStringArray[1];
};
export const TOKEN_KEY = `${process.env.REACT_APP_ENV}-${getTenantName()}_token`;

export const constructFdAppURL = (
  fdAppURl: string,
  passedQueryParams?: { [key: string]: string }
) => {
  const passedFdAppURL =
    process.env.NODE_ENV === 'production'
      ? fdAppURl
      : process.env.REACT_APP_FD_APP_URL;
  if (passedQueryParams) {
    const queryString = Object.entries(passedQueryParams)
      .filter(([, value]) => Boolean(value))
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');
    return `${passedFdAppURL}?${queryString}`;
  }
  return `${passedFdAppURL}`;
};

export const redirectUserForAuthentication = (errorMessage?: string) => {
  return (() => {
    getAppConfig().then((res) => {
      res.data.apps?.forEach((ele: { [key: string]: string }) => {
        if (ele.app === 'auth') {
          if (errorMessage) Toast.error(errorMessage);
          let url =
            process.env.NODE_ENV === 'production'
              ? ele?.url
              : process.env.REACT_APP_AUTH_URL!;
          url += '?redirect_to=';
          url += window.location.pathname;
          url += window.location.search;
          setTimeout(() => {
            window.location.href = url;
          }, 500);
        }
      });
    });
  })();
};

export const formatPrice = (val: number) => {
  return new Intl.NumberFormat('en-IN', {
    currency: 'INR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(val);
};
export const formatNumber = (value: string, place?: number) => {
  if (value) {
    return parseFloat(value).toFixed(place || 2);
  }
  return value;
};

export const formatDate = (dateStr: string) => {
  // Note: Represents date in 01-Jan-1990
  if (dateStr) {
    const date = new Date(dateStr);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + '-' + month + '-' + year;
  }
  return '';
};

export const formatDateToLongFormat = (dateString: string) => {
  // Note: Represents date in 1st January, 1990
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Function to add ordinal suffix to day
  function addOrdinalSuffix(day: number) {
    if (day >= 11 && day <= 13) {
      return day + 'th';
    } else {
      switch (day % 10) {
        case 1:
          return day + 'st';
        case 2:
          return day + 'nd';
        case 3:
          return day + 'rd';
        default:
          return day + 'th';
      }
    }
  }

  // Extract day, month, and year
  const day = addOrdinalSuffix(date.getDate());
  const month = formattedDate.split(' ')[0];
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};

export const formatInput = (e: any) => {
  let symbolsArr = ['e', 'E', '+', '-'];
  const value = e.target.value;
  if (e.keyCode === 40 && value <= 0) {
    return e.preventDefault();
  }
  return symbolsArr.includes(e.key) && e.preventDefault();
};

export const BankListType = [
  { label: 'Saving', value: 'SB' },
  { label: 'Current', value: 'CB' },
];

export const BankListTypeNri = [
  { label: 'NRE', value: 'nre', type: 'NE' },
  { label: 'NRO', value: 'nro', type: 'NO' },
];

export const BankListTypeNre = [{ label: 'NRE', value: 'nre', type: 'NE' }];

export const BankListTypeNro = [{ label: 'NRO', value: 'nro', type: 'NO' }];

export const BankListTypeIndividual = [{ label: 'Saving', value: 'SB' }];

export const BankListTypeCurrent = [{ label: 'Current', value: 'CB' }];

export const AnnualIncomeList = [
  { label: 'Below 1 Lac', value: '31', id: 'below_1_lac' },
  { label: '1 Lacs to 5 Lacs', value: '32', id: 'above_1_lac_upto_5_lac' },
  { label: '5 Lacs to 10 Lacs', value: '33', id: 'above_5_lac_upto_10_lac' },
  { label: '10 Lacs to 25 Lacs', value: '34', id: 'above_10_lac_upto_25_lac' },
  { label: '25 Lacs to 1 Crore', value: '35', id: 'above_25_lac_upto_1_crore' },
  { label: 'Above 1 Crore', value: '36', id: 'above_1_crore' },
];
export const AnnualIncomeListNri = [
  { label: 'Below 1 Lac', value: '31', id: 'below_1_lac' },
  { label: '1 Lacs to 5 Lacs', value: '32', id: 'above_1_lac_upto_5_lac' },
  { label: '5 Lacs to 10 Lacs', value: '33', id: 'above_5_lac_upto_10_lac' },
  { label: '10 Lacs to 25 Lacs', value: '34', id: 'above_10_lac_upto_25_lac' },
  { label: '25 Lacs to 1 Crore', value: '35', id: 'above_25_lac_upto_1_crore' },
  { label: 'Above 1 Crore', value: '36', id: 'above_1_crore' },
];
export const AddressTypeList = [
  { label: 'Residential or Business', value: '00' },
  { label: 'Residential', value: '01' },
  { label: 'Business', value: '02' },
  { label: 'Registered Office', value: '03' },
  { label: 'Unspecified', value: '04' },
];
export const InvesterPoliticallyExposedList = [
  {
    label: 'The investor is politically exposed person',
    value: 'The investor is politically exposed person',
  },
  {
    label: 'The investor is not politically exposed person',
    value: 'The investor is not politically exposed person',
  },
  {
    label: 'If the investor is a relative of the politically exposed person',
    value: 'If the investor is a relative of the politically exposed person',
  },
];
export const OccupationList = [
  { label: 'Business', value: 'B' },
  { label: 'Service', value: 'S' },
  { label: 'Profession', value: 'P' },
  { label: 'Other', value: 'O' },
];
export const OccupationListNew = [
  { label: 'Business', value: 'business' },
  { label: 'Service', value: 'service' },
  { label: 'Profession', value: 'professional' },
  { label: 'Other', value: 'others' },
];
export const NonFinancallyListNew = [
  { label: 'Listed Company', value: 'listed_company' },
  { label: 'Related to listed Company', value: 'related_to_listed_company' },
  { label: 'Active NEFFE', value: 'active_neffe' },
  { label: 'Passive NEFFE', value: 'passive_neffe' },
  { label: 'Not Applicable', value: 'not_applicable' },
];
export const NonFinancallyList = [
  { label: 'Listed Company', value: '01' },
  { label: 'Related to listed Company', value: '02' },
  { label: 'Active NEFFE', value: '03' },
  { label: 'Passive NEFFE', value: '04' },
  { label: 'Not Applicable', value: '05' },
];

export const docTypeList = [
  { value: '00', label: 'PAN Card' },
  { value: '01', label: 'Passport' },
  { value: '02', label: 'Election ID Card' },
  { value: '03', label: 'Company Identification Number' },
  { value: '04', label: 'ID card' },
  { value: '05', label: 'Driving License' },
  { value: '06', label: 'Global Entity Identification Number' },
  { value: '07', label: 'UIDIA/ Aadhar Letter' },
  { value: '08', label: 'US GIIN' },
  { value: '09', label: 'NREGA Job Card' },
  { value: '10', label: 'UIDIA/ Aadhar Letter' },
  { value: '11', label: 'Not Categorized' },
  { value: '12', label: 'Others' },
];
export const wealthSourceList = [
  { value: '01', label: 'Salary' },
  { value: '02', label: 'Business Income' },
  { value: '03', label: 'Gift' },
  { value: '04', label: 'Ancestral Property' },
  { value: '05', label: 'Rental Income' },
  { value: '06', label: 'Prize Money' },
  { value: '07', label: 'Royalty' },
  { value: '08', label: 'Others' },
];
export const wealthSourceListNri = [
  { value: '01', label: 'Salary' },
  { value: '02', label: 'Business Income' },
  { value: '03', label: 'Gift' },
  { value: '04', label: 'Ancestral Property' },
  { value: '05', label: 'Rental Income' },
  { value: '06', label: 'Prize Money' },
  { value: '07', label: 'Royalty' },
  { value: '08', label: 'Others' },
];
export const occupationCodeList = [
  { value: '41', label: 'Private Sector Service' },
  { value: '42', label: 'Public Sector' },
  { value: '01', label: 'Business' },
  { value: '03', label: 'Professional' },
  { value: '04', label: 'Agriculturist' },
  { value: '05', label: 'Retired' },
  { value: '06', label: 'Housewife' },
  { value: '07', label: 'Student' },
  { value: '43', label: 'Forex Dealer' },
  { value: '42', label: 'Government Service' },
  { value: '08', label: 'Others' },
];
/*for fund dtail */
export const FundSizeList = [
  { label: '1000', value: 'Upto 1,000Cr' },
  { label: '2000', value: 'Upto 2,000Cr' },
  { label: '5000', value: 'Upto 5,000Cr' },
  { label: '10000', value: 'Upto 10,000Cr' },
];
export const MobileFundSizeList = [
  { id: '1000', name: 'Upto 1,000Cr' },
  { id: '2000', name: 'Upto 2,000Cr' },
  { id: '5000', name: 'Upto 5,000Cr' },
  { id: '10000', name: 'Upto 10,000Cr' },
];
export const NfoList = [{ id: 'nfo', name: 'NFO' }];
export const RiskometerList = [
  { color: '#95C21F', label: 'Low', value: 50 },
  { color: '#E0DD01', label: 'Low to Moderate', value: 150 },
  { color: '#FEEA12', label: 'Moderate', value: 250 },
  { color: '#F9B234', label: 'Moderately High', value: 350 },
  { color: '#F29200', label: 'High', value: 450 },
  { color: '#E7352A', label: 'Very High', value: 550 },
];
export const getRiskoMeterColor = () => {
  return RiskometerList.map((item) => item.color);
};
export const getReskoMeterValue = (passedValue: string) => {
  const filterData = RiskometerList.filter(
    (item) => (item.label + ' risk').toLowerCase() === passedValue.toLowerCase()
  );
  return filterData && filterData.length ? filterData[0].value : 0;
};

export const downloadFile = (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.download = url.split('/').pop() as string;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const GetFileType = (
  fileType: 'pdf' | 'docx' | 'doc' | 'jpeg' | 'jpg' | 'png'
) => {
  let cls = '';
  switch (fileType) {
    case 'pdf':
      cls = 'application/pdf';
      break;
    case 'docx':
      cls =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'doc':
      cls = 'application/msword';
      break;
    case 'jpeg':
    case 'jpg':
      cls = 'image/jpeg';
      break;
    case 'png':
      cls = 'image/png';
      break;
    default:
      cls = 'application/pdf';
  }
  return cls;
};

export const returnList = [
  { label: '6M Returns', value: 'ttr_return_6_mth', mobileLabel: '6M%' },
  { label: '1Y Returns', value: 'ttr_return_1_yr', mobileLabel: '1Y%' },
  { label: '3Y Returns', value: 'ttr_return_3_yr', mobileLabel: '3Y%' },
];

const getFileName = (key: string) => {
  if (key === 'excel') {
    return 'transactions.xls';
  }
  return 'transactions.' + key;
};
export const handleFileDownload = (eventKey: string, data: string) => {
  //Convert the Byte Data to BLOB object.
  let blob = new Blob([data]);
  if (eventKey === 'excel') {
    blob = new Blob([data], { type: 'application/ms-excel' });
  } else if (eventKey === 'pdf') {
    blob = new Blob([data], { type: 'application/pdf' });
  }
  const downloadUrl = window.URL.createObjectURL(blob);
  // window.open(downloadUrl);
  //Check the Browser type and download the File.
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = getFileName(eventKey);
  a.click();
  a.remove();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const fcWidgetConfig = {
  cssNames: {
    widget: 'custom_fc_frame',
    expanded: 'custom_fc_expanded',
  },
  headerProperty: {
    hideChatButton: true,
  },
};

export const regexString = (str: string) => {
  let fundString = str
    .split('-')
    .join(' ')
    .replace(/\s{2,}/g, ' ');
  let finalString = fundString.replace(/[' ']/g, '-');
  let and = finalString.match(/-[&]-/g);
  let numberArray = finalString.match(/-[\d+]/g);
  let params = finalString.match(/-[(]/g);
  let charArray = [and, numberArray, params];
  let characterArray = Array.prototype.concat.apply([], charArray);
  let filtered = characterArray.filter(Boolean);
  if (filtered.length > 0) {
    filtered.map((character) => {
      let char = character.split('-').join('');
      return (finalString = finalString.split(character).join(char));
    });
  }
  return encodeURIComponent(finalString);
};

export function ordinal_suffix_of(i: number) {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function checkNumberKey(e: KeyboardEvent) {
  const key = e.keyCode;
  console.log('KEY :: ', key, e);
  if (
    (key < 48 || key > 57) &&
    (key < 96 || key > 105) &&
    key !== 110 &&
    key !== 190 &&
    key !== 8
  ) {
    e.preventDefault();
    return false;
  }
}
export function getYearOption() {
  let yearStart = new Date().getFullYear() - 2;
  let yearEnd = new Date().getFullYear() - 1;
  const month = new Date().getMonth() + 1;
  if (month > 6) {
    yearEnd = yearEnd + 1;
    yearStart = yearStart + 1;
  }
  let yearOption = [];
  for (let i = yearStart; i <= yearEnd; i++) {
    yearOption.push({ value: i, label: i });
  }
  return yearOption;
}
export function initiateKYCDetail(userDetail: any) {
  let initiateKYCData = {
    name: userDetail?.name,
    pan: userDetail?.pan,
    email: userDetail?.email,
    mobile: userDetail?.mobile,
    callback_url: `${window.location.origin}/kyc`,
  };
  return initiateKYCData;
}

export function checkAddressDetails(obj: any) {
  let addressData: any = {};
  for (var key in obj) {
    if (obj[key] !== null && obj[key] !== '') {
      addressData[key] = obj[key];
    }
  }

  return addressData;
}

export function setCookie(name: string, value: string, daysToExpire = 1) {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);

  const expires = 'expires=' + date.toUTCString();

  let domain = `domain=.${window.location.hostname
    .split('.')
    .slice(1)
    .join('.')}`;

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    domain = `domain=${window.location.hostname}`;
  }

  document.cookie =
    name + '=' + value + ';' + expires + ';' + domain + ';path=/';
}

export function deleteCookie(name: string) {
  let domain = `domain=.${window.location.hostname
    .split('.')
    .slice(1)
    .join('.')}`;

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    domain = `domain=${window.location.hostname}`;
  }

  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${domain};`;
}

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  setCookie(TOKEN_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  deleteCookie(TOKEN_KEY);
};

export function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function calculateDepositPortfolio(data: any) {
  let total_investment = 0,
    maturity_amount = 0,
    min_interest_rate = Number.MAX_SAFE_INTEGER,
    max_interest_rate = Number.MIN_SAFE_INTEGER;

  data.forEach((record: any) => {
    if (record?.amount) {
      total_investment += record.amount;
    }
    if (record?.maturity_amount) {
      maturity_amount += record.maturity_amount;
    }
    if (record?.interest_rate < min_interest_rate) {
      min_interest_rate = record.interest_rate;
    }

    if (record?.interest_rate > max_interest_rate) {
      max_interest_rate = record.interest_rate;
    }
  });

  return {
    total_investment: total_investment || null,
    maturity_amount: maturity_amount || null,
    min_interest_rate,
    max_interest_rate,
  };
}

export const getLoader = () => {
  const loaderHtml =
    '<div id="loader"><div class="loader"><div></div><div></div><div></div><div></div>';
  const temp = document.createElement('div');
  temp.innerHTML = loaderHtml;
  return temp.firstChild;
};

export const removeLoader = () => {
  const selector = document.getElementById('loader');
  if (selector) {
    selector.remove();
  }
};
